// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kUWLBfqi{display:flex;flex-wrap:wrap;font-size:.88rem;gap:1rem;justify-content:space-between;margin:2.5rem auto 0;max-width:calc(var(--content-width)*1.5);padding:2.5rem 4rem;width:100%}@media(max-width:1023.98px){.kUWLBfqi{flex-direction:column;gap:2rem;padding:2rem var(--spacing-md)}}.dDqC7pQU{width:25rem}.tUd3ohrI{color:var(--color-primary);display:flex;gap:1.5rem}@media(max-width:1229.98px){.tUd3ohrI{flex-direction:column;gap:1rem}}@media(max-width:1023.98px){.tUd3ohrI{flex-direction:row}}@media(max-width:667.98px){.tUd3ohrI{flex-direction:column}}.N4uBmiXT{align-items:center;display:flex;gap:1.5rem;justify-content:flex-end;margin-bottom:1rem}.UoxgzsaM{filter:invert(1);width:2.5rem}.ZzBEDCI7{display:block;text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"footer": "kUWLBfqi",
	"sponsors": "dDqC7pQU",
	"links": "tUd3ohrI",
	"social": "N4uBmiXT",
	"socialLink": "UoxgzsaM",
	"copyright": "ZzBEDCI7"
};
module.exports = ___CSS_LOADER_EXPORT___;
